import React from "react";

import "./Section.css";

function SplitPane(props) {
    return (
        <div className={"section"} id={props.id}>
            {props.children}
        </div>
    )
}

function FourSectionTextPane(props) {
    return (
        <div className={props.className + " fourSectionPane"}>
            {props.children}
        </div>
    );
}

function TextPane(props) {
    return (
        <div className={props.className + " textPane"}>
            <div className="innerDiv">
                {props.children}
            </div>
        </div>
    );
}

function ImagePane(props) {
    return (
        < div id={props.id} className="sectionImage" />
    );
}

export { SplitPane, TextPane, ImagePane, FourSectionTextPane }; 