import React from "react";

import "./Section.css";
import { contact } from "./properties/Contact.properties.js"
import { SplitPane, TextPane, ImagePane } from "./Pane.js";

function Contact(props) {
    return (
        <SplitPane id="contact">
            <ImagePane id="contactImage" />
            <TextPane className="background-firebric-light">
                <h1 className="topic">{contact.topic_sv}</h1>
                <p>{contact.text1_sv}</p>
                <p>{contact.email_text_sv}: <a href={"mailto:" + contact.email_address_sv} target="_new">{contact.email_address_sv}</a></p>
                <p>{contact.phone_text_sv}: {contact.phone_number_sv}</p>
            </TextPane>
        </SplitPane>
    );
}

export default Contact;
