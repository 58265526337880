import React from "react";

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import "./App.css";
import TopSection from "./components/TopSection"
import About from "./components/About"
import Values from "./components/Values"
import Footer from "./components/Footer"
import Education from "./components/Education"
import Contact from "./components/Contact"
import ExpertiseCommitment from "./components/ExpertiseCommitment";
import Metatags from "./components/Metatags";
import FixGedcom from "./components/FixGedcom";



function Home() {
    return (
        <div id="main">
            <Metatags />
            <TopSection />
            <About />
            <ExpertiseCommitment />
            <Education />
            <Values />
            <Contact />
            <Footer />
        </div>
    );
}


export default function App() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path='/fixgedcom' component={() => { window.location = 'https://hittapappa.se/fixgedcom'; return null;} }/>
                </Switch>
            </div>
        </Router>
    );
}
