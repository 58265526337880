import React from "react";

import "./Section.css";
import { consideration, diversity, energy, sustainability } from "./properties/Values.properties.js"
import { SplitPane, ImagePane, FourSectionTextPane } from "./Pane.js";

function QuarterSection(props) {
    return (
        <div className="quarterPane">
            <h1 className="topic">{props.topic}</h1>
            <p>{props.text}</p>
        </div>
    );
}

function Values(props) {
    return (
        <SplitPane id="values">
            <FourSectionTextPane className="background-firebric-dark">
                <QuarterSection topic={consideration.topic_sv} text={consideration.text_sv} />
                <QuarterSection topic={diversity.topic_sv} text={diversity.text_sv} />
                <QuarterSection topic={energy.topic_sv} text={energy.text_sv} />
                <QuarterSection topic={sustainability.topic_sv} text={sustainability.text_sv} />
            </FourSectionTextPane>
            <ImagePane id="valueImage" />
        </SplitPane>
    );
}

export default Values;
