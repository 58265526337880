import React from "react";

import "./Section.css";
import { expertise, partnership, commitment } from "./properties/ExpertiseCommitments.properties.js"
import { SplitPane, TextPane } from "./Pane.js";


function ListItem(props) {
    return <div>
        <li>{props.item.header}</li>
        {props.item.details}
    </div>;
}

function ListItems(items) {
    return items.map((item) =>
        <ListItem key={item.header}
            item={item} />
    );
}

function ExpertiseCommitment() {
    return(
        <SplitPane id="expertise">
            <TextPane className="background-firebric-light">
                    <h1 className="topic">{expertise.topic_sv}</h1>
                    <p>{expertise.text1_sv}</p>
                    <ul>
                        {ListItems(expertise.expertise_sv)}
                    </ul>
            </TextPane>
            <TextPane className="background-firebric-dark">
            <h1 className="topic">{commitment.topic_sv}</h1>
                    <p>{commitment.text1_sv}</p>
                    <p>{commitment.text2_sv}</p>
                    <ul>
                        {ListItems(commitment.commitment_sv)}
                    </ul>
                    <h1 className="topic">{partnership.topic_sv}</h1>
                    <p>{partnership.text1_sv}</p>
                    <p>{partnership.text2_sv}</p>
            </TextPane>
        </SplitPane>
    );
}

export default ExpertiseCommitment;
