export const consideration = {
    topic_sv: "Omtanke",
    text_sv: "Vår arbetsmiljö präglas av gemenskap och omtanke om varandra, våra kunder, samarbetspartners och omvärlden. Vi är lyhörda inför varandras behov och olikheter samtidigt som vi värnar om vår teamkänsla."
}

export const diversity = {
    topic_sv: "Mångfald",
    text_sv: "Vi ser mångfald som någonting som stärker oss som grupp. Vi bygger förtroende genom att agera fördomsfritt och respektfullt mot varandra, våra kunder, samarbetspartners och omvärlden. Genom öppenhet och solidaritet utvecklas och stärks vi som grupp."
}

export const energy = {
    topic_sv: "Energi",
    text_sv: "Genom nyfikenhet och engagemang skapar vi en arbetsmiljö som stimulerar till kreativitet. Alla medarbetare ska både kunna och vilja bidra till varandras och bolagets utveckling. Vi uppmuntrar och hjälper varandra i det praktiska arbetet. Den glädje och energi vi skapar bidrar till vår egen, våra kunders, våra samarbetspartners och omvärldens utveckling."
}

export const sustainability = {
    topic_sv: "Hållbarhet",
    text_sv: "Vårt arbete präglas av meningsfullhet och hållbarhet ur ett socialt, ekonomiskt, miljömässigt och tekniskt perspektiv. Vi agerar alltid professionellt och prestigelöst gentemot varandra, kunder, samarbetspartners och omvärlden. Vi löser uppkomna problem på ett pragmatiskt sätt och kan utvecklas som bolag när förutsättningarna förändras. Genom att reflektera och alltid vara ärliga utvecklar vi hållbara relationer både inom och utom bolaget."
}
