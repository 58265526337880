import React from "react";

import "./Section.css";
import { contact } from "./properties/Contact.properties.js"

const Footer = () => (
    <div id="footer" className="background-firebric-dark">
        <div id="companyname">Firebric AB</div>
        <div id="companyaddress">Rörstrandsgatan 18</div>
        <div id="companyemail">{contact.email_address_sv}</div>
    </div>
);

export default Footer;
