import React, { Component } from "react";
import moment from "moment";

import LogoSection from "./LogoSection"
import Footer from "./Footer"
import { SplitPane, TextPane, ImagePane } from "./Pane.js";

class FixGedcom extends Component {

    constructor() {
        super();
        this.state = {
            name: 'React'
        };
    }

    /** Function that count occurrences of a substring in a string;
     * @param {String} string               The string
     * @param {String} subString            The sub string to search for
     * @param {Boolean} [allowOverlapping]  Optional. (Default:false)
     *
     * @author Vitim.us https://gist.github.com/victornpb/7736865
     * @see Unit Test https://jsfiddle.net/Victornpb/5axuh96u/
     * @see http://stackoverflow.com/questions/4009756/how-to-count-string-occurrence-in-string/7924240#7924240
     */
    occurrences(string, subString, allowOverlapping) {

        string += "";
        subString += "";
        if (subString.length <= 0) return (string.length + 1);

        var n = 0,
            pos = 0,
            step = allowOverlapping ? 1 : subString.length;

        while (true) {
            pos = string.indexOf(subString, pos);
            if (pos >= 0) {
                ++n;
                pos += step;
            } else break;
        }
        return n;
    }

    capitalizeName(name) {
        var ret = name;
        if (!(name.startsWith("de")) &&
            !(name.startsWith("di")) &&
            !(name.startsWith("la")) &&
            !(name.startsWith("le")) &&
            !(name.startsWith("af")) &&
            !(name.startsWith("von")) &&
            !(name.startsWith("van"))) {
            //capitalize all other names
            ret = name.charAt(0).toUpperCase() + name.slice(1);
        }
        return ret;
    }

    cleanupName(name) {
        var ret = name;
        // replace all "special characters" except asterisk
        const regexp = /[|()\[\]",]/gi;
        ret = ret.replace(regexp, "");
        ret = ret.replace(",", "");
        return this.capitalizeName(ret);
    }

    cleanupNameArray(name) {
        return name.map(name => this.cleanupName(name));
    }


    uniformifyMonth(replace, replaceWith, line) {
        var regexpPrefix = "^(.*)(";
        var regexpSuffix = ".*)( .*)";
        var monthRegexp = new RegExp(regexpPrefix + replace + regexpSuffix);
        var monthMatch = line.match(monthRegexp);
        if (monthMatch) {
            line = monthMatch[1] + replaceWith + monthMatch[3];
        }
        return line;
    }


    processLine(prev, line, hasExtractedNames) {


        var givenName = null;
        var surName = null;
        var suffixName = null;

        // if (line.indexOf("CONT") > -1  || line.indexOf("CONC") > -1 || line.indexOf("TIME") > -1 || line.indexOf("OCCU") > -1 || line.indexOf("PLAC") > -1 || line.indexOf("MAP") > -1 || line.indexOf("LATI") > -1 || line.indexOf("LONG") > -1|| line.indexOf("DATE") > -1 || line.indexOf("NOTE") > -1|| line.indexOf("SOUR") > -1) {
        //     line = null;
        // } else {

       // if (line.indexOf("NOTE") > -1) {
         //   line = null;
       // } else {

        // remove lines that shouldn't be there
        // ftdna doesn't seem to handle tags starting with an underscore
        var underscoreTag = line.match(/[0-9]* _.*/);
        var emptyContTag = line.match(/[0-9]* CONT$/m);
        if (underscoreTag || emptyContTag) {
            line = null;
        } else {

            // first check id:s with only zeros, replace with id 999999 
            var id = line.match(/^(.*@[^0-9]*)0+(@.*)/);
            if (id) {
                line = id[1] + "999999" + id[2];
            } else {
                // replace leading zeros in id:s
                let id = line.match(/^(.*@[^0-9]*)0+([^0][0-9]*@.*)/);
                if (id) {
                    line = id[1] + id[2];
                }
            }
            // replace dashes in id:s with 999999
            id = line.match(/^(.*@.*)-(.*@.*)/);
            if (id) {
                line = id[1] + "999999" + id[2];
            }
            // add an "I" if this is an id of an individual
            if (line.indexOf("INDI") > -1 || line.indexOf("CHIL") > -1 || line.indexOf("WIFE") > -1 || line.indexOf("HUSB") > -1) {
                // but only if it starts with a number
                let id = line.match(/^(.*@)([0-9].*)(@.*)/);
                if (id) {
                    line = id[1] + "I" + id[2] + id[3];
                }
            }
            var dateMarkIdx = line.indexOf("DATE");
            if (dateMarkIdx !== -1) {
                line = line.toUpperCase();

                line = this.uniformifyMonth("JAN", "JAN", line);
                line = this.uniformifyMonth("FEB", "FEB", line);
                line = this.uniformifyMonth("MAR", "MAR", line);
                line = this.uniformifyMonth("APR", "APR", line);
                line = this.uniformifyMonth("MAJ|MAY", "MAY", line);
                line = this.uniformifyMonth("JUN", "JUN", line);
                line = this.uniformifyMonth("JUL", "JUL", line);
                line = this.uniformifyMonth("AUG", "AUG", line);
                line = this.uniformifyMonth("SEP", "SEP", line);
                line = this.uniformifyMonth("OKT|OCT", "OCT", line);
                line = this.uniformifyMonth("NOV", "NOV", line);
                line = this.uniformifyMonth("DEC", "DEC", line);

                var dateIdx = dateMarkIdx + 5;
                var date = line.substring(dateIdx);
                // try to parse the date in different formats, and convert it to the form "12 JUN 1948"
                var fixedDate = moment(date, ["DD MMM YYYY", "DD MM YYYY", "YYYY-MM-DD"], "en", true).format("DD MMM YYYY", "en");
                if (fixedDate !== "Invalid date") {
                    line = line.substring(0, dateIdx) + fixedDate.toUpperCase();
                }
            }

            line = this.removeUnhandledCharacters(line, "PLAC", /[:'"]/g);
            line = this.removeUnhandledCharacters(line, "NOTE", /[:{'"]/g);
            line = this.removeUnhandledCharacters(line, "CONC", /[{'"]/g);
            line = this.removeUnhandledCharacters(line, "CONT", /[{'"]/g);

            if (line.indexOf("NAME") !== -1) {

                // replace all but last two slashes
                var nrOfOccurrences = this.occurrences(line, "/", false);
                if (!hasExtractedNames && nrOfOccurrences === 0) {
                    // there is no surname, let's assume the last name in the name tag is the surname
                    // strip first six characters: "1 NAME "
                    var nameStr = line.substring(7);
                    var name = nameStr.split(" ");
                    if (name) {
                        surName = name.pop();
                        if (surName) {
                            surName = "2 SURN " + this.cleanupName(surName);
                            name = this.cleanupNameArray(name);
                            givenName = name.join(" ");
                            if (givenName) {
                                givenName = "2 GIVN " + givenName;
                            }
                        }
                    }
                }

                for (var i = 0; i < nrOfOccurrences - 2; i++) {
                    line = line.replace("/", "");
                }

                // name tag, lets fix GIVN and SURN tags if there are none
                if (!hasExtractedNames) {
                    // Assume name is always on level 1
                    let name = line.match(/(1 NAME )(.*)\/(.*)\/(.*)/);
                    if (name) {
                        var givenNames = this.cleanupNameArray(name[2].split(" ")).join(" ");
                        givenName = "2 GIVN " + givenNames.trim();
                        var surNames = this.cleanupNameArray(name[3].split(" ")).join(" ");
                        surName = "2 SURN " + surNames.trim();
                        if (name[4]) {
                            var suffixNames = this.cleanupNameArray(name[4].split(" ")).join(" ");
                            suffixName = "2 NSFX " + suffixNames.trim();
                        }
                    }
                }
                // replace dashes with space
                var regexp = /-/gi;
                line = line.replace(regexp, " ");
                // remove a bunch of illegal charachters
                regexp = /[\|\(\)\[\]\.{}\\\*"',]/gi;
                line = line.replace(regexp, "");
                // can't for the life of me get a "." to work in the regexp
                //line = line.replace(".", "");
                // replace all but last two slashes
                nrOfOccurrences = this.occurrences(line, "/", false);
                for (i = 0; i < nrOfOccurrences - 2; i++) {
                    line = line.replace("/", "");
                }
                
            }
        }
   // }
        prev = this.addLine(prev, line);
        prev = this.addLine(prev, givenName);
        prev = this.addLine(prev, surName);
        prev = this.addLine(prev, suffixName);
        return prev;
    }



    removeUnhandledCharacters(line, tagName, regexp) {
        if (line.indexOf(tagName) > -1) {
            line = line.replace(regexp, "");
        }
        return line;
    }

    addLine(prev, newLine) {
        if (!newLine) {
            return prev;
        } else {
            return prev + newLine + "\n";
        }
    }

    handleFile = (file) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const file = event.target.result;
                const hasExtractedNames = (file.indexOf("SURN") > -1 || file.indexOf("GIVN") > -1);
                const allLines = file.split(/[\r\n]+/);
                // Sort the lines in number order
                var result = "";
                var prevLevel = 0;
                var toAdd = [];
                allLines.forEach((line) => {
                    // first word should be an int
                    var level = parseInt(line);
                    if (level === 0) {
                        result = this.processLine(result, line, hasExtractedNames);
                        //reset line and level
                        prevLevel = 0;
                    } else if (level === (prevLevel + 2)) {
                        // wrong order, save this
                        toAdd.push(line);
                    } else {
                        // fine, add it, but also add any that are saved
                        result = this.processLine(result, line, hasExtractedNames);
                        toAdd.forEach((lineToAdd) => {
                            result = this.processLine(result, lineToAdd, hasExtractedNames);
                        });
                        toAdd = [];
                        // and set prevLevel
                        prevLevel = level;
                    }
                });
                // force a download by creating a link and faking a click on it
                const element = document.createElement("a");
                const retFile = new Blob([result], { type: 'text/plain' });
                element.href = URL.createObjectURL(retFile);
                element.download = "fixed.ged";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            };
            reader.onerror = (event) => {
                alert(event.target.error.name);
            };
            reader.readAsText(file);
        }
    }



    render() {
        return (
            <div>
                <LogoSection />
                <SplitPane>
                    <TextPane className="background-firebric-dark">
                        <div className="rounded-corners">
                            <input
                                type="file"
                                id="file"
                                className="input-file"
                                accept=".ged"
                                onChange={(e) => this.handleFile(e.target.files ? e.target.files[0] : null)}
                            />
                        </div>
                        <p>Många gedcom-filer som uppladdas till FTDNA resulterar i ett trasigt släktträd, där enbart huvudpersonen syns i det resulterande trädet, eller där namn saknas.</p>
                        <p>Det här verktyget försöker fixa dessa problem genom att justera gedcom-filen till ett format som passar bättre för import hos FTDNA. Många tack till Johanna Lindh och David Hegethorn som har undersökt problemet och föreslagit en lösning.</p>
                        <p>Verktyget försöker</p>
                        <ul>
                            <li>fixa id:n med inledande nollor</li>
                            <li>fixa id:n som innehåller ett bindestreck</li>
                            <li>fixa felaktig radordning, då TYPE har hamnat innan NAME</li>
                            <li>extrahera GIVN-, SURN- och NSFX-taggar när enbart NAME-tagg finns</li>
                            <li>ändra till stor begynnelsebokstav i namn när det behövs</li>
                            <li>ta bort tecken som FTDNA har problem med att importera från NAME-taggen</li>
                            <li>justera datum från format såsom "2000-10-17", "17&nbsp;Okt&nbsp;2000" och "17&nbsp;October&nbsp;2000" till "17&nbsp;OCT&nbsp;2000". Alla andra eventuella format lämnas orörda.</li>
                            <li>fixa id:n som inte innehåller någon bokstav (workaround för bugg som bara uppstår ibland)</li>
                            <li>ta bort programspecifika taggar, som börjar med ett understreck</li>
                            <li>ta bort tecken som FTDNA inte hanterar väl i anteckningar</li>
                            <li>ta bort tecken som FTDNA inte hanterar väl i platsnamn</li>
                        </ul>
                        <p>Klicka på knappen ovan och välj din gedcom-fil från din dator. Den kommer att behandlas i din webbläsare, så den lämnar aldrig din dator (och sparas inte på våra servrar), och resultatet ges i form av en fil 'fixed.ged' i katalogen "Hämtade&nbsp;filer" eller motsvarande katalog på din dator.</p>
                        <p>Begränsningar: Verktyget hanterar inte namn som innehåller tecknet "/" väl - det tecknet tar verktyget bort helt och hållet. Verktyget hanterar inte mer än en miljon individer i ett och samma träd. Det förväntar sig att filen är i UTF-8-format.</p>

                    </TextPane>
                    <TextPane className="background-firebric-light">
                        <p>Many gedcom export files uploaded to FTDNA results in a broken family tree, where only the main person is visible in the resulting tree, or names are missing.</p>
                        <p>This tool attempts to fix these problems by adjusting the gedcom file to a format more suitable for FTDNA's import. Many thanks to Johanna Lindh and David Hegethorn who researched the problem and proposed a solution.</p>
                        <p>The tool attempts to</p>
                        <ul>
                            <li>fix id:s with leading zeros</li>
                            <li>fix id:s that contain a dash</li>
                            <li>fix wrong line order where TYPE appears before NAME</li>
                            <li>extract GIVN, SURN and NSFX tags where only NAME tag is present</li>
                            <li>capitalize names</li>
                            <li>remove characters that FTDNA doesn't handle well in the NAME tag</li>
                            <li>adjust date formats such as "2000-10-17", "17&nbsp;Okt&nbsp;2000" and "17&nbsp;October&nbsp;2000" into "17&nbsp;OCT&nbsp;2000". Any other formats are left untouched.</li>
                            <li>fix id:s that don't contain a letter (workaround for a bug that only occurs sometimes)</li>
                            <li>remove program specific tags, which start with an underscore</li>
                            <li>remove characters that FTDNA doesn't handle well in notes</li>
                            <li>remove characters that FTDNA doesn't handle well in place names</li>
                        </ul>
                        <p>Click the button to the left and choose your gedcom file from your computer. It will be processed in your browser, so it will never leave your computer (and will never be saved on our servers), and the result will be provided as a file named 'fixed.ged' in the "Downloads" folder or corresponding folder on your computer.</p>
                        <p>Limitations: This tool does not handle names with the character "/" well - it merely removes that character. This tool does not handle more than one million individuals in one tree. It expects the file to be encoded witn UTF-8.</p>
                    </TextPane>

                </SplitPane >
                <SplitPane>
                    <ImagePane id="dnaImage" />
                    <ImagePane id="dnaImage2" />
                </SplitPane>
                <Footer />
            </div >
        );
    }


}

export default FixGedcom;