import React from "react";

import { metatags } from "./properties/Metatags.properties.js";
import { Helmet } from "react-helmet";




function Metatags(props) {
    return (
        <Helmet>
            <meta name="description" content={metatags.description_sv} />
        </Helmet>
    )
};

export default Metatags;