import React from "react";
import {Link} from "react-router-dom";


export default function LogoSection() {
    return (
        <div id="logosection">
            <div id="logo">
                <Link to="/">
                    <img id="logoimg"
                        src="./resources/images/logga2-green.png" alt="Firebric logo"
                    />
                </Link>
            </div>
            <div id="socialmedia">
                <a href="http://www.facebook.com/firebric">
                    <img className="icon"
                        src="./resources/icons/facebook.png" alt="Facebook"
                    />
                </a>
                <a href="http://www.linkedin.com/company/firebric">
                    <img className="icon"
                        src="./resources/icons/linkedin.png" alt="LinkedIn"
                    />
                </a>
            </div>
        </div>

    );
}