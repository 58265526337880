export const expertise = {
    topic_sv: "Specialistkompetens",
    text1_sv: "Behöver ni komplettera er egen kompetens med specialistkompetens inom ett specifikt område? Vi har möjlighet att gå in och stötta er där era behov är som störst. Vi har specialister inom en rad olika områden.",
    expertise_sv: [
        { header: "Frontend", details: "Våra specialister kan hjälpa er att skapa framtidssäkra webbgränssnitt med populära verktyg såsom React och Angular." },
        { header: "Backend", details: "Vi kan hjälpa er att designa och vidareutveckla era system med fokus på hållbarhet. Flera av våra medarbetare har mångårig erfarenhet av Java, Python, Scala och andra moderna språk." },
        { header: "Arkitektur", details: "Våra kunniga systemarkitekter ger er språnget in i det nya decenniet. Firebric är med er hela vägen från skiss till produktion och dokumenterar alla steg för att säkerställa en smidig överlämning." },
        { header: "Test", details: "Vi erbjuder experter inom systemtest för att tillse att era produkter klarar den påfrestning som era kunder förväntar sig. Vi har kompetensen som krävs för att sätta upp automatiserade tester och övervakning som håller er teknikstack ajour och era system robusta." },
        { header: "Drift", details: "Vi har tekniker med mångårig erfarenhet och olika bakgrund. Oavsett om ni vill automatisera era byggkedjor med DevOps, lyfta in era system i molnet eller uppgradera era befintliga Unix-system så har vi vad ni behöver." },
        { header: "Projektledning", details: "Att hålla ihop team är en kompetens som oftast prioriteras ner eller helt läggs åt sidan. Vi kan hjälpa er med allt från Scrum Master till produktägarskap så att ni kan fokusera på det ni gör bäst." }
    ]


}

export const partnership = {
    topic_sv: "Partnerskap",
    text1_sv: "Behöver ni utveckla nya system för att hänga med i en omvärld som digitaliseras allt snabbare eller har ni precis startat upp en ny verksamhet? Många av de företag vi jobbar med saknar egna resurser för att ta fram de nya system som krävs för att utveckla verksamheten.",
    text2_sv: "Vi tar ett helhetsansvar för er systemutveckling och stöttar er i er digitaliseringsresa. Vår specialistkompetens inom systemutveckling gör att vi kan hjälpa ert företag att dra nytta av teknikens möjligheter. Vi eftersträvar långsiktiga partnerskap och kan tillföra kompetens i alla delar av utvecklingsprocessen, från projektledning och verksamhetsutveckling till systemutveckling och systemförvaltning."
}
export const commitment = {
    topic_sv: "Åtaganden",
    text1_sv: "Vill ni ha en nyckelfärdig lösning? Då kan vi ta ansvar för hela projekt som sträcker sig över längre tid. Det finns många fördelar med att välja Firebric för den här typen av åtaganden. Vi hjälper er att definiera och leda projektet från början till levererad lösning. Det gör att arbetet blir mer effektivt och säkerställer att lösningen lever upp till de krav ni har. Vi tar ansvaret för projektet vilket ger både tidsvinster och flexibilitet eftersom vi kan bemanna projektet med den kompetens som behövs. Vi arbetar med moderna agila processer där ni ges möjlighet att kontinuerligt styra projektets inriktning.",
    text2_sv: "Exempel på större åtaganden är bland annat utveckling av",
    commitment_sv: [
        { header: "Webbplatser", details: "Vi utvecklar en helt ny webbplats eller moderniserar er befintliga webbplats och integrerar den med era övriga system." },
        { header: "Appar", details: "Om ni vill ha mobil access till era system kan vi utveckla mobila lösningar som fungerar för både iOS och Android." },
        { header: "System", details: "När ni vill utveckla ett helt nytt system tar vi ansvar för hela processen; från kravspecifikation, systemering och implementation till färdig driftsatt produkt." }
    ]
}
