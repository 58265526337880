import React from "react";

import "./Section.css";
import { about } from "./properties/About.properties.js";
import { SplitPane, TextPane, ImagePane } from "./Pane.js";




function About(props) {
    return (
        <SplitPane id="about">
            <TextPane className="background-firebric-dark">
                <h1 className="topic">{about.topic_sv}</h1>
                <p>{about.text1_sv}</p>
                <p>{about.text2_sv}</p>
            </TextPane>
            <ImagePane id="aboutImage" />
        </SplitPane>
    )
};

export default About;