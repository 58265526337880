import React from "react";

import "./Section.css";

import MenuItem from "./MenuItem";
import LogoSection from "./LogoSection";

const TopSection = () => (
    <div id="topSection">
        <div id="navbar">
            <ul>
                <MenuItem to="about" title="Om oss" orderNumber="0" />
                <MenuItem to="expertise" title="Vårt erbjudande" orderNumber="1" />
                <MenuItem to="education" title="Utbildning" orderNumber="2" />
                <MenuItem to="values" title="Våra värderingar" orderNumber="3" />
                <MenuItem to="contact" title="Kontakta oss" orderNumber="4" />
            </ul>
        </div>
        <LogoSection />
        <div className="holder" id="topImageHolder">
            <div className="innerDiv">
                <img id="topImage"
                    src="./resources/images/stuff_full.jpg" alt=""
                />
            </div>
        </div>



    </div>
);

export default TopSection;
