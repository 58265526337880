import React from "react";

import "./Section.css";
import { education } from "./properties/Education.properties.js"
import { SplitPane, TextPane, ImagePane } from "./Pane.js";

function Education(props) {
    return (
        <SplitPane id="education">
            <ImagePane id="educationImage" />
            <TextPane className="background-firebric-light">
                <h1 className="topic">{education.topic_sv}</h1>
                <p>{education.text1_sv}</p>
            </TextPane>
        </SplitPane>
    );
}

export default Education;
