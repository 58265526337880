import React from "react";
import { Link } from "react-scroll";

const scrollBaseDuration = 1000;
const scrollSpeedAdjustment = 170;

const MenuItem = (props) => (
    <Link
        activeClass="active"
        to={props.to}
        spy={true}
        smooth={true}
        duration={scrollBaseDuration + scrollSpeedAdjustment * props.orderNumber}>
        <li>
            {props.title}
        </li>
    </Link>
);

export default MenuItem;
